<template>
  <area-container>
    <area-nav :links="links"></area-nav>
    <area-content>
      <router-view></router-view>
    </area-content>
  </area-container>
</template>

<script>

import { mapGetters } from "vuex"

export default {

  computed: {

    ...mapGetters(["location"]),

    links () {
      
      let arr = [        { 
          route: "dispatch.Dispatch", 
          name: this.$tk("DispatchLayout.DispatchOrders") 
        },
        { 
          route: "dispatch.Scheduled", 
          name: this.$tk("DispatchLayout.ScheduledPickups")
        }
      ]

      if (this.location.returnDays > 0) {
        arr.push({ 
          route: "dispatch.Returns", 
          name: this.$tk("DispatchLayout.Returns") 
        })
      }

      return arr
    }
  }
}
</script>
